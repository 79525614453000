import React from 'react'
import { images } from 'config/images'

export const IOSAppDevComProps = {
  ppcbannerProps: {
    heading: (
      <>
        <span className="highlight-head">iPhone app development</span> company
      </>
    ),
    para: (
      <>
        Creative iOS development company that develops{' '}
        <span className="color-through">native applications</span> for iPhone,
        iPad, Apple Watch, Apple TV and iPod
      </>
    ),
    formCTA: 'Hire an iOS developer',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        We build <span className="highlight-head"> best-in-class</span> mobile
        experiences for iOS
      </>
    ),
    buildAppPara: (
      <>
        We have a team of{' '}
        <span className="color-through">full stack iPhone app developers</span>{' '}
        with rich industry experience and knowledge of the latest iOS 12 and
        programming languages such as Swift, XCode and Objective C.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our Key Services as an
        <br />
        <span className="highlight-head">iOS application</span> development
        company
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Custom iOS App Solutions',
        serviceParagraph: (
          <>
            Customized mobile solutions to{' '}
            <strong>drive your operations</strong> using integration with AR/VR,
            blockchain, IoT, AI etc. For example, a CMS to organize and publish
            content, all in a simple screen.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Native iOS App Development Services',
        serviceParagraph: (
          <>
            We <strong>develop, maintain and improve</strong> native iOS apps
            using the latest OS and programming tools such as Xcode, Cocoa APIs,
            and Code Runner.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Hybrid Cross-platform App Development Services',
        serviceParagraph: (
          <>
            Utilize the power of React Native, and Flutter to develop powerful{' '}
            <strong>apps for multiple platforms</strong> while sharing the same
            code base.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'Manual',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'iPhone App Testing and Upgrade',
        serviceParagraph: (
          <>
            We ensure that the final product is <strong>free from bugs</strong>{' '}
            and fulfils the end user’s requirements. We also add new
            functionalities and upgrade your app’s compatibility for the latest
            version of Apple devices.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Software',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Business Analysis & Consulting Services',
        serviceParagraph: (
          <>
            Whether you are looking to develop{' '}
            <strong>business apps or B2C apps</strong> to service customers, our
            consultants can help you define the right tech plan.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'DevOps',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Business Apps and API Integration Services',
        serviceParagraph: (
          <>
            We <strong>integrate your mobile app(s)</strong> with services you
            are already using and extend their use cases for your employees and
            consumers.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Performance',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with <br />
        Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Strategy and consulting to set the right goals',
        workingBenefitsContent: (
          <>
            We co-create apps by understanding your business needs first. We
            identify processes that can be streamlined and build a{' '}
            <strong>tech delivery roadmap</strong> to align with your goals.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Product development approach to mobile apps',
        workingBenefitsContent: (
          <>
            Our team takes your ideas and turns them into apps with high ROI and
            retention rates. Our <strong>UX driven approach</strong> with user
            research, usability testing, and design thinking ensure an engaging
            app.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Incorporate Security Best Practices',
        workingBenefitsContent: (
          <>
            Mobile app <strong>testing best practice</strong> reduces the
            security risks, tests potential vulnerabilities and incorporates
            data encryption. This ensures that an application, APIs and its data
            is secure from potential attacks.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Case studies on how{' '}
        <span className="highlight-head">iOS applications</span> helped these
        companies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/mvc-mvp-mvvm-ios-app-development/',
        featurecardTitle:
          'Ending the debate: MVC vs MVP vs MVVM for iOS application development',
      },
      {
        blogLink: 'https://www.simform.com/mobile-app-performance/',
        featurecardTitle: 'How to Improve your Mobile App’s Performance?',
      },
      {
        blogLink:
          'https://www.simform.com/mobile-application-security-data-vulnerabilities/',
        featurecardTitle:
          'Mobile Application Security: Checklist for Data Security and Vulnerabilities',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactJignesh,
    personImg2x: images.contactJignesh2x,
    personName: 'Jignesh Solanki',
    personDesignation:
      'Employing a consultative approach and building a team of tech experts to deliver the best solution.',
    personEmail: 'jignesh@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
